<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
// import $ from "jquery";

export default {
    page: {
        title: "Detail Izin Penggunaan TUK",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
    },

    data() {
        return {
            title: "Detail Izin Penggunaan TUK",
            items: [
                {
                    text: "Penggunaan TUK",
                    href: "/penggunaan-tuk",
                },
                {
                    text: "Detail Izin Penggunaan TUK",
                    active: true,
                },
            ],
            // Catch Error Axios
            axiosCatchError: null,

            // variabel referensi
            optionsMenuParent: [],
            id: this.$route.params.id,
            //modal
            showModalSuratPenggunaan: false,
            preview_surat_penggunaan : null,
            showModalSuratBalasan: false,
            preview_surat_balasan : null,
            meta_asesor: [
                {
                    'tuk': 'Ruang Meeting LSP',
                    'tanggal_asesmen': '1 Agustus 2023'
                },
            ],
            data_kepala_tuk: [],
            link_backend: process.env.VUE_APP_BACKEND_URL_VERSION,
            data_penggunaan : {
                penggunaan_nomor: '',
                penggunaan_tanggal: '',
                perihal: '',
                skema_code: '',
                skema_name: '',
                status_nama: '',
                pdf: '',
            },
            meta_tuk: [],
            showModalPersetujuanKetuaLsp: false,
            showKonfigsuratBalasan : false,
            showLoadingPreviewSuratBalasan: false,
            showPreviewSuratBalasan: false,
            showModalSubmitKetuaLSP: false,
            showModalValidasi: false,
            showModalAsesor: false,
            showLoading: false,
            showPreview: false,

            sessionRoleId: localStorage.session_role_active_id,
            sessionUserId: localStorage.getItem("session_user_id"),
            sessionUsername: localStorage.getItem("session_username"),
            session: JSON.parse(localStorage.session),
            roleKetuaLSP : "99dc7617-56b7-4e69-950b-1da996a2250d",

            qr_path: null,
            disclaimer_surat_tugas: false,
            modalPasswordKetuaLSP: false,
            
            loadingTable: false,
            statusValidasi: null,
            optionsPegawai: [],
          
            path_draft_surat_balasan: null,
            disclaimerSuratBalasan: false,
            password: null,

            // data surat balasan
            nomor_surat_balasan: '',
            tanggal_surat_balasan:  new Date().toISOString().slice(0, 10),
            hal_surat_balasan: 'Balasan Persetujuan Pemakaian Ruangan sebagai Tempat Uji Kompetensi (TUK) Kompetensi LSP BUMN Farmasi',
            keterangan_surat_balasan: '',
            showModalPreviewSuratBalasan : false,
            preview_surat_surat_balasan: null,
            backend_url: process.env.VUE_APP_BACKEND_URL,

        };
    },
    mounted() {
        // this.getDataTable();
        this.getDetailPenggunaan();
        if (this.sessionRoleId == 'd3369dce-46e4-11ee-a6d3-0242ac110009') {
            this.getPegawai();
        }
    },
    methods: {
        addAsesor() {
            this.meta_asesor.push({
                tuk: "",
                tanggal_asesmen: "",
            });
        },
        removeAsesor(index) {
            this.meta_asesor.splice(index, 1);
        },
        modalSurat() {
            let self = this;
            self.showModalSuratPenggunaan = false;
            // config untuk re render component
            self.$nextTick(() => {
                self.showModalSuratPenggunaan = true;
            });
        },
        getDataTable(
            url = process.env.VUE_APP_BACKEND_URL_VERSION + "dokumen/files"
        ) {
            let self = this;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token
                },
            };

            axios(config)
                .then((response) => {
                    console.log(response);
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        showSuratPenggunaanValidasi() {
            let self = this;
            self.$router.push({ name: "add-surat_tugas_validasi" });
        },
        getDetailPenggunaan() {
            // get data detail penyusunan berdasarkan id
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/show",
                params: {
                    id: self.id,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.status == true) {
                        var data_edit = response_data_fix;
                        self.data_penggunaan = data_edit;
                        self.data_kepala_tuk = data_edit.list_kepala_tuk;
                        self.preview_surat_penggunaan = data_edit.pdf;

                        var currentDate = new Date();
                        self.nomor_surat_balasan = data_edit.urutan + "/SB/LSP/" + self.convertToRoman(currentDate.getMonth() + 1) + "/" + currentDate.getFullYear();
                        self.loadingTable = false;
                   
                        Swal.close();
                    } else {
                        Swal.close();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    Swal.close();
                });
        },
        ModalPersetujuanKetuaLsp() {
            let self = this;
            self.showModalPersetujuanKetuaLsp = false;
            // config untuk re render component
            self.showLoading = true;
            self.changeSuratTugas();
            self.$nextTick(() => {
                self.showModalPersetujuanKetuaLsp = true;
            });
        },
        ModalPassword(){
            let self = this
            self.showModalPersetujuanKetuaLsp = false
            self.showKonfigsuratBalasan = false
            self.modalPasswordKetuaLSP = false
            self.$nextTick(() => {
                self.modalPasswordKetuaLSP = true
            })
        },
        ModalKonfigurasiSuratBalasan(){
            let self = this
            self.showKonfigsuratBalasan = false
            self.$nextTick(() => {
                self.showKonfigsuratBalasan = true
            })
        },
        statusValidasiModal(status) {
            let self = this;
            if (status == "tolak") {
                self.showModalValidasi = true;
                self.statusValidasi = status;
            } else if  (status == "submit-ketua-lsp") {
                self.statusValidasi = "submit-ketua-lsp"
                self.sendEmail();
                self.storeUbahStatusPenggunaanTUK();
            } else if (status == "submit-kepala-tuk") {
                self.statusValidasi = "submit-kepala-tuk"
                self.storeUbahStatusPenggunaanTUK();
            }
            else {
                self.showModalValidasi = true;
                self.statusValidasi = status;
            }
        },
        
        storeUbahStatusPenggunaanTUK(){
            let self = this;
            self.showModalSubmitKetuaLSP = false;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });

            var config_store_data = {
                method: "put",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/ubah-status",
                data: {
                    id: self.id,
                    status: self.statusValidasi,
                },
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config_store_data)
                .then(function (response) {
                    console.log(response);
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Anda akan diarahkan ke halaman list penggunaan tuk",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                            Swal.close();
                            // close all modal
                            self.showModalValidasi = false;
                            self.showModalAsesor = false;
                            self.modalPasswordKetuaLSP = false;
                            self.modalPassword = false;
                            self.$router.push({ name: "all-penggunaan_tuk" });
                        }
                    });
                })
                .catch(function (error) {
                    // this.axiosCatchError = e.response;
                    Swal.fire({
                        icon: "error",
                        title: "Gagal",
                        text: error.response?.data?.data?.error,
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    console.log(error);
                    self.axiosCatchError = error.response.data.data.error;
                });
        },
        getPegawai(){
            let self = this;

            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-pegawai",
                headers: {
                Accept: "application/json",
                Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.optionsPegawai = response.data.data.referensi;
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        previewSuratBalasan(){
            this.showLoadingPreviewSuratBalasan = false;
            this.showPreviewSuratBalasan = true;
            let self = this;

            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/add-surat-balasan-tuk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                data: {
                    id: self.id,
                    meta_tuk: self.meta_tuk,
                    nomor_surat_balasan: self.nomor_surat_balasan,
                    tanggal_surat_balasan: self.tanggal_surat_balasan,
                    hal_surat_balasan: self.hal_surat_balasan,
                    keterangan_surat_balasan: self.keterangan_surat_balasan,
                    role_id : self.sessionRoleId,
                },
            };
            axios(config)
                .then(function (response) {
                var response_data = response.data;
                var response_data_fix = response_data.data;
                if (response_data.meta.code == 200) {
                    self.path_draft_surat_balasan = response_data_fix.pdf;
                    console.log(response_data_fix);
                } else {
                    Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response_data_fix.data.message,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });

        },
        convertToRoman(num) {
            var roman = {
                M: 1000,
                CM: 900,
                D: 500,
                CD: 400,
                C: 100,
                XC: 90,
                L: 50,
                XL: 40,
                X: 10,
                IX: 9,
                V: 5,
                IV: 4,
                I: 1
            };
            var str = '';

            for (var i of Object.keys(roman)) {
                var q = Math.floor(num / roman[i]);
                num -= q * roman[i];
                str += i.repeat(q);
            }

            return str;
        },
        sendEmail(){
            let self = this;

            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/send-email-ketua-tuk",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                data: {
                    id: self.id,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        console.log(response_data_fix);
                    } else {
                        Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        changeSuratTugas(){
            let self = this;

            var config = {
                method: "post",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "penggunaan-tuk/change-status-doc",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
                data: {
                    id: self.id,
                    qr: true,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.showLoading = false;
                        self.showPreview = true;
                        self.preview_surat_penggunaan = response_data_fix.pdf;

                    } else {
                        Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        modalSuratBalasan(){
            let self = this;
            self.showModalSuratBalasan = false;
            self.$nextTick(() => {
                self.showModalSuratBalasan = true;
            });
        },
        ModalPreviewSuratBalasan($path_surat_balasan){
            let self = this;
            self.preview_surat_surat_balasan = null;

            self.preview_surat_surat_balasan = self.backend_url +'/'+ $path_surat_balasan;
           
            self.showModalPreviewSuratBalasan = false;
            self.$nextTick(() => {
                self.showModalPreviewSuratBalasan = true;
            });
        }
      
    },
};
</script>

<template>
<Layout>
<PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card-body">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td style="width: 350px"><strong>Nomor Surat Tugas Penyusunan</strong></td>
                                            <td>:  {{ data_penggunaan.penggunaan_nomor }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Tanggal Surat Tugas Penyusunan</strong></td>
                                            <td>:  {{ data_penggunaan.penggunaan_tanggal }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Perihal</strong></td>
                                            <td>:  {{ data_penggunaan.perihal }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Status</strong></td>
                                            <td>:  {{ data_penggunaan.status_nama }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Surat Permohonan</strong></td>
                                            <td>: 
                                                <button class="btn btn-sm btn-primary" @click="modalSurat">
                                                    <i class="fas fa-eye"></i> 
                                                    <span class="mx-2">Lihat Surat</span>
                                                </button>
                                            </td>
                                        </tr>
                                        <!-- <tr v-if="data_penggunaan.status_id == '420fda6e-4b93-11ee-913b-0242ac110006'">
                                            <td><strong>Surat Balasan</strong></td>
                                            <td>: 
                                                <button class="btn btn-sm btn-success" @click="modalSuratBalasan">
                                                    <i class="fas fa-eye"></i> 
                                                    <span class="mx-2">Lihat Surat</span>
                                                </button>
                                            </td>
                                        </tr> -->
                                        <!-- <tr v-if="row_data.status_id == 'ae042eb8-4b94-11ee-913b-0242ac110006'"> -->
                                            <!-- <td><strong>Surat Balasan</strong></td>
                                            <td>: 
                                                <button class="btn btn-sm btn-success" @click="modalSurat">
                                                    <i class="fas fa-eye"></i> 
                                                    <span class="mx-2">Lihat Surat</span>
                                                </button>
                                            </td> -->
                                        <!-- </tr> -->

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div class="row">
                    <div class="col-md-12 col-md-6">
                        <label for="example-text-input" class="col-form-label mt-4">List Kepala Tuk</label>
                        <table class="table mb-0 table-bordered table-condensed table-hover mb-4">
                            <thead class="bg-dark text-center text-white">
                                <tr>
                                    <th style="width: 50px">No</th>
                                    <th class="text-center" style="width: 30%">Nama Kepala Tuk</th>
                                    <th class="text-center" style="width: auto">Surat Balasan</th>
                                </tr>
                            </thead>
                                <tbody class="text-center">
                                <tr v-if="data_kepala_tuk.length == 0">
                                    <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                </tr>
                                <tr v-else v-for="(data, index) in data_kepala_tuk" :key="index">
                                    <td> {{ index + 1 }} </td>
                                    <td>
                                        {{ data.kepala_nik }} - {{ data.kepala_nama }}
                                    </td>
                                    <td class="text-center">
                                        <div v-if="data?.penggunaan_kepala_surat_balasan?.path_surat_balasan != null">
                                            <b-button @click="ModalPreviewSuratBalasan(data?.penggunaan_kepala_surat_balasan?.path_surat_balasan)" type="button" variant="primary" class="btn-sm">
                                                <i class="fas fa-eye"></i> Lihat Surat Balasan
                                            </b-button>
                                        </div>
                                        <div v-else>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            <b-modal v-model="showModalPreviewSuratBalasan" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
                <div class="row">
                    <div class="form-group">
                        <div class="card-body">
                            <iframe v-bind:src="preview_surat_surat_balasan" width="100%" height="400"></iframe>
                        </div>
                    </div>
                </div>
            </b-modal>
            <div class="row">
                <div class="col-md-12 col-md-6">
                    <div class="text-end">
                        <template v-if="sessionRoleId == 'd3369dce-46e4-11ee-a6d3-0242ac110009' || data_penggunaan.status_id == '420fda6e-4b93-11ee-913b-0242ac110006'" >
                            <router-link :to="{ name: 'all-penggunaan_tuk' }" class="btn btn-sm btn-danger bg-danger m-1">
                                <i class="fa fa-times"></i>
                                Tolak</router-link>
                            <button @click="ModalKonfigurasiSuratBalasan" class="btn btn-sm btn-success bg-success m-1">
                                <i class="fas fa-save"></i> Terima
                            </button>
                        </template> 
                        <template v-else>
                            <button @click="ModalPersetujuanKetuaLsp" class="btn btn-sm btn-primary bg-primary m-1">
                                <i class="fas fa-save"></i> Submit
                            </button>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <b-modal v-model="showModalPersetujuanKetuaLsp" title="Preview Penggunaan TUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>

                                <tr>
                                    <td><strong>Tanggal Surat Tugas</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_tanggal }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <div v-if="showLoading">
                        <i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Penggunaan ...
                    </div>
                    <div v-else-if="showPreview">
                        <h4>Preview Draft Surat Penggunaan</h4>
                        <div class="card-body">
                            <iframe v-bind:src="preview_surat_penggunaan" width="100%" height="400"></iframe>
                        </div>
                    </div>
                </div>
                <div class="form-group"><input type="checkbox" v-model="disclaimer_surat_tugas" /> Apakah Anda yakin ingin menerbitkan surat tugas tersebut?</div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalPersetujuanKetuaLsp = false"><i class="fas fa-times"></i> Tutup</button>
                    <button type="button" class="btn btn-primary mt-3" v-if="disclaimer_surat_tugas" @click="ModalPassword"><i class="fas fa-save"></i> Simpan</button>
                    <button type="button" class="btn btn-primary mt-3" v-else disabled><i class="fas fa-save"></i> Simpan</button>
                </div>
            </form>
        </div>
    </b-modal>
   
    <b-modal v-model="showKonfigsuratBalasan" title="Konfig Surat Balasan" title-class="text-black font-18" body-class="p-3" hide-footer size="lg">
            <div class="row">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row d-flex flex-wrap mb-2" id="example text"
                            role="group">
                            <label class="col-lg-5 col-form-label col-form-label" id=""
                                for="text">Tanggal Surat Balasan <span class="required-field"></span></label>
                            <div class="col">
                                <input id="" class="form-control" type="date" for="text"
                                    v-model="tanggal_surat_balasan">
                            </div>
                        </div>
                        <div class="row d-flex flex-wrap mb-2" id="example text"
                            role="group">
                            <label class="col-lg-5 col-form-label col-form-label" id=""
                                for="text">Nomor Surat Balasan <span class="required-field"></span></label>
                            <div class="col">
                                <input class="form-control" type="text" for="text"
                                    v-model="nomor_surat_balasan">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">

                        <div class="row d-flex flex-wrap mb-2" id="example text"
                            role="group">
                            <label class="col-lg-4 col-form-label col-form-label" id=""
                                for="text">Perihal <span class="required-field"></span></label>
                            <div class="col">
                                <input v-model="hal_surat_balasan" class="form-control" readonly type="text"
                                    for="text">
                            </div>
                        </div>

                        <div class="row d-flex flex-wrap mb-2" id="example text"
                            role="group">
                            <label class="col-lg-4 col-form-label col-form-label" id=""
                                for="text">Keterangan</label>
                            <div class="col">
                                <textarea v-model="keterangan_surat_balasan" class="form-control" type="text"
                                    for="text"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 mt-3 text-end">
                        <button class="btn btn-info btn-sm" @click="previewSuratBalasan()">Generate Draft Surat Balasan</button>
                    </div>
                </div>
                <div class="row mt-3">
                    <div v-if="showLoadingPreviewSuratBalasan">
                        <i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Balasan ...
                    </div>
                    <div v-else-if="showPreviewSuratBalasan">
                        <h4>Preview Draft Surat Balasan</h4>
                        <div class="card-body">
                            <iframe v-bind:src="path_draft_surat_balasan" width="100%" height="400"></iframe>
                        </div>
                    </div>
                </div>
                <div v-if="showLoadingPreviewSuratBalasan">
                    <i class="fas fa-spinner fa-spin"></i> Loading Generate Surat Balasan ...

                </div>
                <div v-else>
                    <div class="row mt-2" v-if="showPreviewSuratBalasan">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="checkbox" v-model="disclaimerSuratBalasan"> Apakah ingin melanjutkan ke proses selanjutnya?
                            </div>
                        </div>
                        <div class="col-md-6 text-end">
                            <div class="text-end">
                                <b-button v-if="disclaimerSuratBalasan == true" type="button" class="btn-sm" variant="primary" @click="ModalPassword" ><i class="fas fa-save"></i> Proses Submit</b-button>
                                <b-button v-else type="button" class="btn-sm" variant="secondary"><i class="fas fa-save"></i> Proses Submit</b-button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- </form> -->
            </div>
        </b-modal>

    <b-modal v-model="modalPasswordKetuaLSP" title="Konfirmasi Password" title-class="text-black font-18" body-class="p-3" hide-footer>
        <div class="row">
            <div class="form-group">
                <input type="password" class="form-control" v-model="password" placeholder="Password Anda" />
            </div>
            <div class="form-group text-end">
                <button  v-if="sessionRoleId == 'd3369dce-46e4-11ee-a6d3-0242ac110009' || data_penggunaan.status_id == '420fda6e-4b93-11ee-913b-0242ac110006'" 
                    class="btn btn-primary mt-3" @click="statusValidasiModal('submit-kepala-tuk')">
                        <i class="fas fa-save"></i> Konfirmasi
                </button>
                <button v-else class="btn btn-primary mt-3" @click="statusValidasiModal('submit-ketua-lsp')">
                    <i class="fas fa-save"></i> Konfirmasi
                </button>
            </div>
        </div>
    </b-modal>
    <b-modal v-model="showModalSuratPenggunaan" title="Preview Surat Penggunaan TUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Tugas</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Tugas</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_tanggal }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="preview_surat_penggunaan" width="100%" height="400"></iframe>

                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalSuratPenggunaan = false"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </form>
        </div>
    </b-modal>
     <b-modal v-model="showModalSuratBalasan" title="Preview Surat Balasan TUK" title-class="text-black font-18" size="lg" body-class="p-3" hide-footer>
        <div class="row">
            <form>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-bordered table-striped">
                            <tbody>
                                <tr>
                                    <td style="width: 350px"><strong>Nomor Surat Balasan</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_nomor }}</td>
                                    <td rowspan="2" v-if="qr_path" style="width: 150px">
                                        <img v-bind:src="qr_path" alt="" style="width: 100%" />
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Tanggal Surat Balasan</strong></td>
                                    <td>: {{ data_penggunaan.penggunaan_tanggal }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="form-group">
                    <iframe v-bind:src="preview_surat_penggunaan" width="100%" height="400"></iframe>

                </div>
                <div class="form-group text-end">
                    <button type="button" class="btn btn-secodary mt-3" @click="showModalSuratBalasan = false"><i class="fas fa-times"></i> Tutup</button>
                </div>
            </form>
        </div>
    </b-modal>
</Layout>

</template>
